
export type MESSAGE_TYPE = 'ERROR' | 'WARNING' | 'MESSAGE'

interface Props {
    text: string,
    type: MESSAGE_TYPE
}

export const ErrorBox = (props: Props) => {

    let errorIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V24H0V0Z" fill="#D8D8D8" fillOpacity="0.01" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.3714 17.8458L23.453 18.0403L14.8814 2.98882C14.148 1.70691 13.1247 1 12.0014 1C10.878 1 9.85469 1.70691 9.12135 2.99221L0.631353 17.8458C-0.103647 19.1311 -0.201981 20.3454 0.36302 21.3279C0.92802 22.3105 2.04302 22.8162 3.51135 22.8162H20.4914C21.9597 22.8162 23.078 22.3088 23.6397 21.3279C24.2014 20.3471 24.1064 19.1311 23.3714 17.8458ZM12.0013 20.3268C11.4058 20.324 10.9252 19.8318 10.928 19.2275C10.9308 18.6232 11.4158 18.1356 12.0113 18.1384C12.6049 18.1412 13.0847 18.6303 13.0847 19.2326V19.2326C13.0837 19.8378 12.5995 20.3277 12.003 20.3268C12.0024 20.3268 12.0019 20.3268 12.0013 20.3268H12.0013ZM13.0763 6.60963L12.723 15.5881C12.723 15.9925 12.3999 16.3204 12.0013 16.3204C11.6028 16.3204 11.2797 15.9925 11.2797 15.5881L10.923 6.61132C10.8453 6.00935 11.2633 5.45747 11.8565 5.37866C12.4498 5.29985 12.9937 5.72395 13.0713 6.32592C13.0836 6.42065 13.0836 6.51659 13.0713 6.61132L13.0763 6.60963Z" fill="white" />
        </svg>
    )

    let messageIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_2953_17121)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24ZM19.342 6.78223C19.0377 6.50128 18.5632 6.52026 18.2822 6.82462L9.60246 16.2276L6.05109 12.3803C5.77013 12.076 5.29564 12.057 4.99127 12.3379C4.68691 12.6189 4.66793 13.0934 4.94889 13.3977L9.05136 17.842C9.34833 18.1638 9.85659 18.1638 10.1536 17.842L19.3844 7.84205C19.6654 7.53768 19.6464 7.06319 19.342 6.78223Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2953_17121">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )



    let boxClass = ''
    let boxIcon = null
    switch (props.type) {
        case 'ERROR':
            boxClass = 'errorInfo';
            boxIcon = errorIcon;
            break;
        case 'MESSAGE':
            boxClass = 'messageInfo';
            boxIcon = messageIcon;
            break;

        default:
            break;
    }



    return (

        <div id='ERROR' className={'newInfo ' + boxClass}>
            <div className='errorIcon'>
                {boxIcon}
            </div>
            <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
        </div>

    )
}