import { useRef, useState } from "react"
import { logFE } from "../services/services"



let LocalProps = {
    onFilled: (code: string) => { },
    code: "",
    setCode: (code: string) => { }
}


type Props = typeof LocalProps

export const BigInput = (props: Props) => {




    let refs: any[] = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ]

    let ref1 = useRef(null)



    // const keyDown = (e: any, position: number) => {
    //     logFE('back')
    //     if (e.code === 'Backspace') {
    //         if (e.target.value === '') {
    //             if (position > 0) {
    //                 refs[position - 1].current.focus()
    //                 let newcode = props.code.substring(0, props.code.length - 1)
    //                 props.setCode(newcode)
    //             }
    //         }
    //     }

    // }




    // const manageInput = (e: any, position: number) => {
    //     // logFE(e)
    //     let val = e.target.value


    //     // logFE('val=', val)
    //     if (val === '') {
    //         logFE('empty')
    //         if (position > 0) {
    //             refs[position - 1].current.focus()
    //         }
    //     } else {
    //         let newCodeValue = props.code + val

    //         if (props.code.length === position) {
    //             props.setCode(newCodeValue)
    //         }
    //         logFE('full')
    //         if (position < (refs.length - 1)) {
    //             refs[position + 1].current.focus()
    //         } else {
    //             logFE('onFilled')
    //             props.onFilled(newCodeValue)
    //         }

    //     }

    // }


    const onchange = (e: any) => {
        const val = e.target.value

        // val.replace(/[^0-9]/g, '');
        // logFE(val)
        props.setCode(val)
        if (val.length >= 6) {
            props.onFilled(val)
        }

    }





    return (
        <div className="otpinput">
            <input id="vodafone_otp_input" type="number"  autoComplete="one-time-code" autoFocus value={props.code} 
            onChange={onchange} maxLength={6}></input>
            <svg xmlns="http://www.w3.org/2000/svg" width="184" height="1" viewBox="0 0 184 1" fill="none">
                <rect x="32" width="24" height="1" fill="#262626" />
                <rect width="24" height="1" fill="#262626" />
                <rect x="64" width="24" height="1" fill="#262626" />
                <rect x="96" width="24" height="1" fill="#262626" />
                <rect x="128" width="24" height="1" fill="#262626" />
                <rect x="160" width="24" height="1" fill="#262626" />
            </svg>

            {/* <svg viewBox="0 0 430 1" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="1" x2="430" y2="1" stroke="black" stroke-dasharray="44,33" />
            </svg> */}

            {/* <input id='c1' autoFocus value={props.code.length > 0 ? props.code[0] : ''} ref={refs[0]} type="text" maxLength={1} onChange={e => manageInput(e, 0)} autoComplete="off" onKeyDown={e => keyDown(e, 0)} disabled={false} />
            <input id='c2' autoComplete="off" value={props.code.length > 1 ? props.code[1] : ''} ref={refs[1]} type="text" maxLength={1} onChange={e => manageInput(e, 1)} onKeyDown={e => keyDown(e, 1)} />
            <input id='c3' autoComplete="off" value={props.code.length > 2 ? props.code[2] : ''} ref={refs[2]} type="text" maxLength={1} onChange={e => manageInput(e, 2)} onKeyDown={e => keyDown(e, 2)} />
            <input id='c4' autoComplete="off" value={props.code.length > 3 ? props.code[3] : ''} ref={refs[3]} type="text" maxLength={1} onChange={e => manageInput(e, 3)} onKeyDown={e => keyDown(e, 3)} />
            <input id='c5' autoComplete="off" value={props.code.length > 4 ? props.code[4] : ''} ref={refs[4]} type="text" maxLength={1} onChange={e => manageInput(e, 4)} onKeyDown={e => keyDown(e, 4)} />
            <input id='c6' autoComplete="off" value={props.code.length > 5 ? props.code[5] : ''} ref={refs[5]} type="text" maxLength={1} onChange={e => manageInput(e, 5)} onKeyDown={e => keyDown(e, 5)} /> */}

        </div >
    )
}