import { useEffect, useState } from 'react';
import './base.css';
import { RouterProvider, createMemoryRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './state/store';
import LoginPage from './pages/LoginPage';
import EnrollStart from './pages/EnrolStart';
import WaitingComponent from './components/WaitingComponent';
import Registrati from './pages/Registrati';
import EnrollSelectNumber from './pages/EnrollSelectNumber';
import { getDFFromStorage, isMock, loadUCMDatafile, logFE, saveDFInStorage, setBaseUrl } from './services/services';
import { Navbar } from './components/Navbar';
import EnrollEnd from './pages/EnrollmentEnd';
import { MockBar } from './components/MockBar';
import { setApp, setRedirectURL, setWaitingAction } from './state/appdata';

const routes = [
  {
    path: "/",
    element: <LoginPage />
  }, {
    path: "/login",
    element: <LoginPage />
  }, {
    path: "/register",
    element: <Registrati />
  }, {
    path: "/enroll",
    element: <EnrollStart />
  }, {
    path: "/enrollSetNumber",
    element: <EnrollSelectNumber />
  }, {
    path: "/enrollend",
    element: <EnrollEnd />
  }
];

const router = createMemoryRouter(routes, {
  initialEntries: ["/", "/login"],
  initialIndex: 1,
});






function App() {
  const [ucmloaded, setUcmloaded] = useState(false)
  const [isApp, setIsApp] = useState(false)
  // const { store } = useContext(ReactReduxContext)
  useEffect(() => {
    const searchParams: any = new URLSearchParams(document.location.search)
    logFE(searchParams.get('app'))
    if (searchParams.get('app') == 'true') {
      setIsApp(true)
      store.dispatch(setApp(true))
    }
    if (searchParams.get('redirectURL')) {
      let URL = searchParams.get('redirectURL')
      store.dispatch(setRedirectURL(URL))
      setIsApp(true)
    }
    if (searchParams.get('baseURL')) {
      let URL = searchParams.get('baseURL')
      setBaseUrl(URL)
    }

  }, [])



  useEffect(() => {
    logFE('checking DF')
    // load UCM datafile
    if (!ucmloaded) {
      store.dispatch(setWaitingAction(true))
      if(getDFFromStorage()){
        store.dispatch(setWaitingAction(false))
        setUcmloaded(true)
      }else{
        loadUCMDatafile().then((d) => {
          logFE('df loaded :', d)
          store.dispatch(setWaitingAction(false))
          setUcmloaded(true)
          saveDFInStorage(d)
        }).catch(e => {
          store.dispatch(setWaitingAction(false))
        })
      }
    
    } else {
      logFE('DF already present')
    }

  }, [])



  return (
    <Provider store={store}>

      <div className={"App" + (store.getState().appdata.app ? ' Mobile' : '')}>
        {ucmloaded ? (<div></div>) : (<div></div>)}
        <Navbar />



        <RouterProvider router={router} />
        <WaitingComponent />

        {/* <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Registrati />} />
            <Route path="/enroll" element={<EnrollStart />} />
            <Route path="/enrollSetNumber" element={<EnrollSelectNumber />} />
          </Routes> */}

      </div>
      {
        isMock() ? (<MockBar />) : (<div></div>)
      }


    </Provider>
  );
}

export default App;
