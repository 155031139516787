import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setNavigateAction } from '../state/userdata'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { retrieveUser, login } from '../services/services'




const mapState = (state: RootState) => ({
    appdata: state.appdata,
})


const mapDispatch = {
    setNavigate: (navigate: any) => (setNavigateAction(navigate)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps

const WaitingComponent = (props: Props) => {



    if (props.appdata.waiting > 0) {
        return (
            <div className="background">
                <svg className='loader' width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                    <g fill="none" fillRule="evenodd" strokeWidth="2">
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="0s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="0s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="-0.9s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="-0.9s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                    </g>
                </svg>
            </div>
        )
    }
    else
        return (
            <div></div>
        )



}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(WaitingComponent)