import React, { useEffect } from 'react';
import '../base.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../state/store';
import SelezionaNuovoNumero from '../components/SelezionaNuovoNumero';
import VerificaIdentita from '../components/VerificaIdentita';
import EnrollSetNumberComponent from '../components/EnrollSetNumberComponent';
import InsertNumberManually from '../components/InsertNumberManually';
import { GL } from '../services/services';
import { tagEnrollSelectNumberPage } from '../services/utag';
import AccessoNonDisponibile from '../components/AccessoNonDisponibile';
import NumeroNonValido from '../components/NumeroNonValido';
import ErrorInEnrollment from '../components/ErrorInEnrollment';




const mapState = (state: RootState) => ({
    appdata: state.appdata,
    userdata: state.userdata,
    vodaprofile: state.vodaprofile

})


const mapDispatch = {
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps



function EnrollSelectNumber(props: Props) {
    const navigate = useNavigate()

    useEffect(() => {
        tagEnrollSelectNumberPage();
    }, [])

    let popup = <div></div>
    switch (props.appdata.currentPopup) {
        case 'NONE':
            break;
        case 'VERIFICA_IDENTITA':
            //show verifica Identita popup
            popup = <div className="background">
                <VerificaIdentita IS_ENROLLMENT={true} />
            </div>
            break;
        case 'INSERISCI_NUOVO_NUMERO':
            // show select new number popup
            popup = <div className="background">
                <SelezionaNuovoNumero />
            </div>

            break;
        case 'INSERISCI_NUMERO_MANUALMENTE':
            popup = <div className="background">
                <InsertNumberManually />
            </div>

            break;
        case 'ACCESSO_NON_DISPONIBILE':
            popup = <div className="background">
                <AccessoNonDisponibile />
            </div>

            break;
        case 'NUMERO_NON_VALIDO':
            popup = <div className="background">
                <NumeroNonValido />
            </div>
            break;
            case 'ENROLLMENT_ERROR':
            popup = <div className="background">
                <ErrorInEnrollment />
            </div>
            break;
        default:
            break;

    }

    return (
        <div className="Page EnrollSelectNumber">
            <div className="header">
                <div className="section" >
                    <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_VF_LOGO') }}></div>

                </div>
            </div>
            <EnrollSetNumberComponent />
            {popup}

        </div >
    );
}

// export default LoginPage;

export default connect(
    mapState,
    mapDispatch
)(EnrollSelectNumber)