import { stat } from "fs";
import { login } from "../services/services"
import { Action } from "./action"


const InitialState = {
    role: "",
    bos: [],
    contactNums: []
}





const SET_BOS_ACTION_TYPE: string = 'SET_BOS'
const SET_CONTAT_NUMS_ACTION_TYPE: string = 'SET_CONTACT_NUMS'

export const setBOSAction = (bos: String[]): Action => {
    return ({
        type: SET_BOS_ACTION_TYPE,
        payload: bos
    })
}
export const setContactNumsAction = (conts: String[]): Action => {
    return ({
        type: SET_CONTAT_NUMS_ACTION_TYPE,
        payload: conts
    })
}




export function vodaReducer(state = InitialState, action: any) {
    switch (action.type) {
        case SET_BOS_ACTION_TYPE:
            return { ...state, bos: action.payload }
        case SET_CONTAT_NUMS_ACTION_TYPE:
            return { ...state, contactNums: action.payload }
        default:
            return state
    }
}