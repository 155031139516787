import { useEffect } from 'react';
import '../base.css';
import { useNavigate } from 'react-router-dom';
import { GL } from '../services/services';
import { loginEnd } from '../services/common-ui';
import { tagEnrollEndPage } from '../services/utag';

function EnrollEnd() {
    const navigate = useNavigate()

    const endLogin = (e: any) => {
        loginEnd()
    }


    useEffect(()=>{
        tagEnrollEndPage();
    },[])

    return (
        <div className="Page EnrollEnd">
            <div className="header">
                <div className="section" >
                    <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_VF_LOGO') }}></div>

                </div>
            </div>

            <div className='mainContent'>
                {/* <img src={GL('ED_MFA_IMG_USER')} alt="" /> */}
                <div className='userlogo' dangerouslySetInnerHTML={{ __html: GL('ED_MFA_TY_IMG') }}></div>
                <h2>{GL('ED_MFA_TY_TITLE')}</h2>
                <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_TY_DES') }}></h3>
                <div className='actionbutton'>
                    <button onClick={endLogin}>{GL('ED_MFA_TY_BUTTON')}</button>
                </div>
                {/* <div className='actionbutton impostazioni'>
                    <button onClick={e => { navigate('/enrollSetNumber') }}>Vai alle impostazioni</button>
                </div> */}
            </div>



        </div >

    );
}

export default EnrollEnd;