import { setAppPathAction, setCurrentPopupAction, setLoginError, setRedirectURL, setWaitingAction } from "../state/appdata"
import { setAuthTokenAction, setChallengeIdAction } from "../state/serviceData"
import { store } from "../state/store"
import { setFactorIdAction, setFactorListAction, setIsPendingAction, setLoginStatusAction, setMarketAction, setPendingFactorListAction, setSelectedMSISDNAction, setUsernameAction } from "../state/userdata"
import { setBOSAction, setContactNumsAction } from "../state/vodaprofile"
import { AuthFactor, UserDetails } from "./be_interfaces"
import { GL, LOGGED, LOGGED_NOT_AUTH, NOT_LOGGED, addFactor, enroll, logFE, login, loginStatus, retrieveUser, sendOTP, setBaseUrl } from "./services"
import { tagLoginPageError } from "./utag"

const isFullyEnrolledUser=(user:UserDetails)=>{
    // check if there is at least one factor  with verified=true
    if(user.authFactors){
        for(let f of user.authFactors){
            if(f.verified && user.defaultFactorId==f.id){
                return true
            }
        }
    }

    return false
}



export const performValidation = (navigate: any) => {
    return new Promise((resolve, reject) => {
        retrieveUser(store.getState().userdata.username).then((d: UserDetails) => {
            store.dispatch(setWaitingAction(false))

            if (d.picassoNumsNonDXL) {
                store.dispatch(setBOSAction(d.picassoNumsNonDXL))
            }
            if(d.contactNums){
                store.dispatch(setContactNumsAction(d.contactNums))
            }
            if (d.authToken) {
                store.dispatch(setAuthTokenAction(d.authToken))
            }
            if (isFullyEnrolledUser(d) && d.authFactors) {
                // we found registered factors for the user
                store.dispatch(setFactorListAction(d.authFactors))
                for (let f of d.authFactors) {
                    if (d.defaultFactorId === f.id) {
                        logFE('found default', f)
                        store.dispatch(setWaitingAction(true))
                        if (f && f.id && f.profileSms) {
                            logFE('selecting the default', f)
                            store.dispatch(setSelectedMSISDNAction(f.profileSms.msisdn))
                            store.dispatch(setFactorIdAction(f.id))
                        }

                        sendOTP(store.getState().userdata.username, f, store.getState().service.authToken).then(s => {
                            logFE("returned by sendOTP", s)
                            if (s.id) {
                                store.dispatch(setWaitingAction(false))
                                store.dispatch(setChallengeIdAction(s.id))
                                store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'))
                            } else {
                                logFE("not pending")
                                store.dispatch(setWaitingAction(false))
                                //TODO: manage error
                                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                            }

                        }).catch(e => {
                            logFE("error by sendOTP", e)
                            store.dispatch(setWaitingAction(false))
                            store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                        })


                    }
                }

            } else {
                // no factors are registered for the user
                // start enroll
                if (!isFullyEnrolledUser(d) && d.authFactors) {
                    store.dispatch(setIsPendingAction(true))
                    store.dispatch(setPendingFactorListAction(d.authFactors))
                }
                store.dispatch(setAppPathAction('MFA_ENROLL'))
                navigate('/enroll')
            }
        }).catch(e => {
            store.dispatch(setWaitingAction(false))
            //TODO: manage error
            store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
        })
    })
}


export const getLoginStatus = (navigate: any) => {
    store.dispatch(setWaitingAction(true))
    const searchParams: any = new URLSearchParams(document.location.search)
    if (searchParams.get('baseURL')) {
        let URL = searchParams.get('baseURL')
        setBaseUrl(URL)
    }

    loginStatus().then((resp) => {
        if(resp.userName){
            store.dispatch(setUsernameAction(resp.userName))
        }
        switch (resp.loginStatus) {
            case LOGGED_NOT_AUTH:
                performValidation(navigate).then(d => {
                    store.dispatch(setWaitingAction(false))
                }).catch((e) => {
                    store.dispatch(setWaitingAction(false))
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                })
                break;
            case NOT_LOGGED:
                store.dispatch(setWaitingAction(false))
                break;
            case LOGGED:
                store.dispatch(setWaitingAction(false))
                let targetURL = resp.loginURL || null;

                loginEnd(targetURL)
                break;
        }
    }).catch((e) => {
        logFE('error on loginStatus', e)
        store.dispatch(setWaitingAction(false))
        store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
    })
}



export const performLogin = (navigate: any) => {
    return new Promise((resolve, reject) => {
        store.dispatch(setWaitingAction(true))
        login(store.getState().userdata.username, store.getState().userdata.password).then(
            (d1) => {
                store.dispatch(setLoginStatusAction('LOGGED'))
                store.dispatch(setWaitingAction(false))
                logFE('returned data from login:', d1)
                if (d1 && d1.status && d1.status < 300) {
                    // let logStatus = store.getState().userdata.loginStatus
                    // console.log('login status:', logStatus)
                    if (d1.status === 201) {
                        if (!store.getState().appdata.redirectURL && d1.location) {
                            store.dispatch(setRedirectURL(d1.location));
                        }
                        if(d1.market){
                            store.dispatch(setMarketAction(d1.market))
                        }
                        
                        store.dispatch(setWaitingAction(true))
                        performValidation(navigate).then((d) => {
                            store.dispatch(setWaitingAction(false))
                            resolve(d)
                        }).catch(e => {
                            store.dispatch(setWaitingAction(false))
                            reject(e)
                        })

                    } else if (d1.status == 200) {
                        logFE('redirecting to', d1.location)
                        loginEnd(d1.location)
                    } else {
                        store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                    }
                } else {
                    if (d1) {
                        if (d1.status == 401) {
                            logFE('error code:', d1.errorCode, GL(d1.errorCode))
                            let error_msg=GL(d1.errorCode);
                            tagLoginPageError(error_msg)
                            store.dispatch(setLoginError(error_msg))
                            resolve(d1)
                            return
                        }
                        if (d1.status == 403) {
                            console.log('loc:', d1.location)

                            if (d1.location) {
                                let isAccountPending=d1.errorCode && (d1.errorCode.indexOf('BL_ACCOUNT_PENDING')>=0)
                                if (store.getState().appdata.app && !isAccountPending) {

                                    let error_msg=GL(d1.errorCode) || 'errore generico';
                                    tagLoginPageError(error_msg)
                                    //we have been called by app mobile don't do redirects
                                    store.dispatch(setLoginError(error_msg))
                                    resolve(d1)
                                    return
                                } else {

                                    window.location.href = d1.location
                                    return

                                }
                            }

                            store.dispatch(setLoginError(d1.diagnostic || 'errore generico'))
                            resolve(d1)
                            return
                        }
                    }

                    store.dispatch(setLoginStatusAction('UNLOGGED'))
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }
            }).catch(e => {
                store.dispatch(setWaitingAction(false))
                store.dispatch(setLoginStatusAction('UNLOGGED'))
                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
            })
    })

}


export const loginEnd = (target: String | null = null) => {
    // get the target page after login

    logFE('login end....')

    let targetURL = store.getState().appdata.redirectURL
    if (!targetURL || targetURL.length == 0) {
        targetURL = target || 'https://www.vodafone.it'
    }
    logFE('switching to', targetURL)
    window.location = targetURL

}


export const createFactorForEnroll = () => {
    return (
        {
            kind: 'sms',
            label: 'default',
            profileSms: {
                msisdn: store.getState().userdata.selectedMSISDN,
                language: 'it-IT'
            }
        }
    )
}



export const doEnroll = () => {

    // check if user is pending
    if (store.getState().userdata.isPending) {

        // check is selected MSISDN is the one pending in user from DXL
        let msisdn = store.getState().userdata.selectedMSISDN
        if(msisdn.indexOf('00')==0){
            msisdn='+'+msisdn.substring(2)
        }
        let numberFound = false
        let factorFound = null
        if(store.getState().userdata.pendingFactors){
            for (let f of store.getState().userdata.pendingFactors) {
                if (f.profileSms.msisdn.indexOf(msisdn) >= 0) {
                    // we can create a new challenge on this number
                    numberFound = true
                    factorFound = f
                }
            }
        }
        if(store.getState().userdata.mfaFactors){
            for (let f of store.getState().userdata.mfaFactors) {
                if (f.profileSms.msisdn.indexOf(msisdn) >= 0) {
                    // we can create a new challenge on this number
                    numberFound = true
                    factorFound = f
                }
            }
        }
       
        if (numberFound) {
            // let's create a new challenge
            store.dispatch(setFactorIdAction(factorFound.id));
            sendOTP(store.getState().userdata.username, factorFound, store.getState().service.authToken).then(s => {
                logFE("returned by sendOTP", s)
                if (s.id) {
                    store.dispatch(setWaitingAction(false))
                    store.dispatch(setChallengeIdAction(s.id))
                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'))
                } else {
                    logFE("not pending")
                    store.dispatch(setWaitingAction(false))
                    //TODO: manage error
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }

            }).catch(e => {
                logFE("error by sendOTP", e)
                store.dispatch(setWaitingAction(false))
                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
            })



        } else {
            // let's add a new Factor to the user
            addFactor(store.getState().userdata.username, createFactorForEnroll(), store.getState().service.authToken).then((r) => {
                store.dispatch(setWaitingAction(false))
                if (r?.id) {
                     store.dispatch(setChallengeIdAction(r.id))
                    store.dispatch(setFactorListAction([r.factor]))
                    if(r.factor.id){
                        store.dispatch(setFactorIdAction(r.factor.id))
                    }
                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'));
                }else{
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }

            }).catch(e => {
                store.dispatch(setWaitingAction(false))
                logFE('error invoking addFactor', e, e.response)
                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                return;
            })
        }

    } else {
        enroll(store.getState().userdata.username, createFactorForEnroll(), store.getState().service.authToken).then(d => {
            store.dispatch(setWaitingAction(false))
            if (d) {
                logFE('response enroll :', d)
                if (d.status && d.id ) {
                    if (d.factor && d.factor.id && d.id) {
                        store.dispatch(setIsPendingAction(true));
                        store.dispatch(setChallengeIdAction(d.id))
                        store.dispatch(setFactorListAction([d.factor]))
                        store.dispatch(setFactorIdAction(d.factor.id))
                    } else {
                        logFE('error invoking enroll', d)
                        store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'))
                        return;
                    }
                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'));

                } else {
                    if(d.reason && d.reason==='Validation failed'){
                        logFE('number id not valid', d)
                        // store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                        store.dispatch(setCurrentPopupAction('NUMERO_NON_VALIDO'))
                    }else{
                        logFE('status is not pending', d)
                        store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'))
                    }
                    
                }
            } else {
                logFE('response is empty', d)
                store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'));
            }
        }).catch(e => {
            // do something in case of error
            store.dispatch(setWaitingAction(false))
            store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'));
        })
    }


}


export const isMobile=()=>{
    return store.getState().appdata.app
}



export const clearMSISDNSelection=()=>{
    try {
        store.dispatch(setSelectedMSISDNAction(''));
        store.dispatch(setFactorIdAction(''));
    } catch (error) {
        
    }
}


export const isNumberMSISDN=()=>{

    let n:string|undefined=store.getState().userdata.selectedMSISDN

    if(!n || n?.length<5){
        return false;
    }

    if((n?.indexOf('+')===0 && n.indexOf('+39')!=0) || (n?.indexOf('00')==0 && n.indexOf('0039')!=0)){
        // not an italian number do not perform any check on format
        return true
    }

    if(n){
        if(n.indexOf('+39')==0){
            n=n.substring(3)
        }
        if(n.indexOf('0039')==0){
            n=n.substring(4)
        }
        if(n.indexOf('+')>=0){
            return false
        }
        if(n.length<9 || n.length>10 || n.indexOf('3')!=0){
            return false
        }
    }else{
        return false
    }


    return true
}


export const thereIsOnlyOneDXLEnrolled=()=>{
    if(store.getState().userdata.mfaFactors && store.getState().userdata.mfaFactors.length>1){
        return false
    }
    return true
}