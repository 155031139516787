import { ReactNode, useEffect, useRef, useState } from "react"
import { logFE } from "../services/services"

type Props = {
    children: ReactNode
}


export default function Slider({ children }: Props) {
    const fatherDivRef = useRef(null);
    const slideDivRef = useRef(null);
    const boxRef = useRef(null);
    const [sel, setSel] = useState(1)

    const scrolling = (evt: any) => {
        // get the quantity of scrol
        if (slideDivRef.current) {
            const viewWidth = (slideDivRef.current as any).clientWidth;
            const scrollLeft = (fatherDivRef.current as any).scrollLeft
            const scrollPerc=scrollLeft/viewWidth
            if(scrollPerc < 0.20){
                setSel(1)
            }else if(scrollPerc < 0.45){
                setSel(2)
            }else{
                setSel(3)
            }
           // logFE('scroll percentage:', scrollLeft / viewWidth)
        }
    }




    const scroll = (asel: number) => {
        setSel(asel);
        logFE('scrolling:', sel)
        if (boxRef && boxRef.current) {

            const divWidth = (boxRef.current as any).clientWidth;
            if (slideDivRef.current) {
                // (slideDivRef.current as any).style.width = "" + (divWidth * 3 + 200) + " px";
                const viewWidth = (slideDivRef.current as any).clientWidth;
                logFE('larghezza view:', viewWidth)
                let scollamount = (asel - 1) * (viewWidth / 3);
                console.log("scroll amount:", scollamount);
                (fatherDivRef.current as any).scrollLeft = scollamount;

            }
        }


    }

    // logFE('children', children)
    const getChildrens = () => {
        let Items = []
        let i = 0
        for (let c of (children as any[])) {
            Items.push((
                <div key={"" + i++} ref={(i == 0) ? boxRef : null} className="isliderItem">{c}</div>
            ))
        }
        return Items
    }
    return (
        <div>

            <div className="islider" ref={fatherDivRef} onScroll={scrolling}>
                <div className={"isliderView"} ref={slideDivRef}>
                    {getChildrens()}
                </div>




            </div >
            <ul className='slider'>
                <li className={sel == 1 ? 'active' : ''} onClick={e => { scroll(1) }}></li>
                <li className={sel == 2 ? 'active' : ''} onClick={e => { scroll(2) }}></li>
                <li className={sel == 3 ? 'active' : ''} onClick={e => { scroll(3) }}></li>

            </ul >
        </div>
    )
}