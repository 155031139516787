import React, { useEffect, useState } from 'react';
import '../base.css';
import { NavLink } from 'react-router-dom';
import LoginRegNavigatorComponent from '../components/LoginRegNavigator';
import { GL } from '../services/services';
import Slider from '../components/Slider';
import { isMobile } from '../services/common-ui';
import { tagRegistrationPage } from '../services/utag';

function Registrati() {

    useEffect(()=>{
        tagRegistrationPage();
    },[])
    const [sel, setSel] = useState(1)


    return (
        <div className='Page RegistrationPage'>
            <LoginRegNavigatorComponent page={'REGISTER'} />
            <div id="tab_registration">
                < div className="mainContent" >



                    <h3>{GL('ED_MFA_REG_TITLE')}</h3>

                    <div className="actionsButton" >
                        <button dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BUTTON') }} onClick={e => {
                            if (isMobile()) {
                                window.location.href = GL('ED_MFA_REG_BUTTON_LINK_APP')
                            } else {
                                window.location.href = GL('ED_MFA_REG_BUTTON_LINK')
                            }
                        }
                        }>
                        </button>
                    </div>



                </div>

                <div className="secondaryContent">
                    <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_TEXT') }}></h3>
                    {/* <div className="boxes">

                        <div className={"sliderBox " + "set" + sel}>
                            < div className="box">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="apps">
                                        <g id="Group">
                                            <path id="Vector" d="M21.6429 37.1171H13.445C13.1877 37.1281 12.932 37.0714 12.7035 36.9525C12.475 36.8337 12.2817 36.657 12.1429 36.4401C12.0175 36.2163 11.9543 35.9631 11.9598 35.7066C11.9653 35.4502 12.0393 35.1999 12.1741 34.9817L16.2783 27.2135C16.388 26.976 16.5634 26.775 16.7838 26.634C17.0041 26.4931 17.2602 26.4182 17.5218 26.4182C17.7834 26.4182 18.0395 26.4931 18.2598 26.634C18.4802 26.775 18.6556 26.976 18.7653 27.2135L22.932 34.9791C23.0676 35.197 23.1421 35.4474 23.1476 35.704C23.1531 35.9606 23.0894 36.2139 22.9632 36.4375C22.8236 36.658 22.6276 36.8373 22.3955 36.9568C22.1634 37.0763 21.9035 37.1317 21.6429 37.1171V37.1171Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                            <path id="Vector_2" d="M17.5439 22.2031C16.6218 22.2037 15.7202 21.9306 14.9532 21.4186C14.1862 20.9066 13.5884 20.1786 13.2352 19.3267C12.8821 18.4749 12.7895 17.5374 12.9693 16.6329C13.1491 15.7284 13.5931 14.8976 14.2452 14.2455C14.8973 13.5935 15.7281 13.1494 16.6326 12.9697C17.5371 12.7899 18.4745 12.8824 19.3264 13.2356C20.1783 13.5887 20.9063 14.1866 21.4183 14.9535C21.9303 15.7205 22.2033 16.6221 22.2028 17.5443C22.2014 18.7795 21.7101 19.9637 20.8367 20.8371C19.9633 21.7105 18.7791 22.2018 17.5439 22.2031V22.2031Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                            <path id="Vector_3" d="M32.4554 26.1641L37.932 30.5469L35.7419 37.1172H29.1715L26.9814 30.5469L32.4554 26.1641Z" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path id="Vector_4" d="M35.0469 12.8828H29.8672C28.7238 12.8828 27.7969 13.8097 27.7969 14.9531V20.1328C27.7969 21.2762 28.7238 22.2031 29.8672 22.2031H35.0469C36.1903 22.2031 37.1172 21.2762 37.1172 20.1328V14.9531C37.1172 13.8097 36.1903 12.8828 35.0469 12.8828Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                            <path id="Vector_5" d="M11.4582 7.29166H42.7082V38.5417C42.7082 39.6467 42.2692 40.7065 41.4878 41.4879C40.7064 42.2693 39.6466 42.7083 38.5415 42.7083H11.4582C10.3531 42.7083 9.29329 42.2693 8.51189 41.4879C7.73049 40.7065 7.2915 39.6467 7.2915 38.5417V11.4583C7.2915 10.3533 7.73049 9.29345 8.51189 8.51204C9.29329 7.73064 10.3531 7.29166 11.4582 7.29166V7.29166Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" />
                                        </g>
                                    </g>
                                </svg>
                                <h3>Gestione offerte</h3>
                                <h5>Lorem ipsum Tieni sotto controllo i consumi e le opzioni delle offerte attiveo modificale in qualsiasi momento.</h5>
                            </div>
                            <div className="box">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="document">
                                        <g id="Group">
                                            <path id="path 1" d="M32.2528 21.9375H17.6694" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path id="path 1_2" d="M32.2528 28.1537H17.6694" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                            <path id="path 1_3" d="M32.2528 34.375H17.6694" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                            <path id="Shape" d="M11.9453 13.7162H19.8568V6.461" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path id="Vector" d="M21.5703 5.48703C20.5112 5.48705 19.4867 5.86364 18.6797 6.54953L13.0469 11.3594C12.5623 11.7727 12.1731 12.2861 11.9061 12.8642C11.6391 13.4424 11.5005 14.0715 11.5 14.7084V40.5417C11.5 42.823 13.375 44.6875 15.125 44.6875H34.3333C37.1667 44.6875 38.5 42.823 38.5 40.5443V5.48703H21.5703Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        </g>
                                    </g>
                                </svg>
                                <h3>Controllo costi</h3>
                                <h5>Lorem ipsum Puoi consultare e scaricare le tue fatture non fiscali, pagarle online e gestire le opzioni di fatturazione e il metodo di pagamento.</h5>
                            </div>
                            <div className="box">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="top-up">
                                        <path id="Vector" d="M21.7915 40.6901H28.0415" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector_2" d="M12.4165 38.6068C17.5942 38.6068 21.7915 37.1494 21.7915 35.3516C21.7915 33.5537 17.5942 32.0963 12.4165 32.0963C7.23883 32.0963 3.0415 33.5537 3.0415 35.3516C3.0415 37.1494 7.23883 38.6068 12.4165 38.6068Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" />
                                        <path id="Vector_3" d="M21.7915 35.3516V41.4974C20.5675 42.9323 16.8436 43.6849 12.4165 43.6849C7.98942 43.6849 4.26546 42.6432 3.0415 41.2083V35.2188" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector_4" d="M5.125 42.5234V46.6901C6.34896 48.1224 10.0729 49.0338 14.5 49.0338C18.9271 49.0338 22.651 48.3828 23.875 46.9505V41.7422C23.875 41.7422 23.875 40.7005 21.7917 40.7005" stroke="#E60000" strokeWidth="2" strokeLinejoin="round" />
                                        <path id="Vector_5" d="M13.5415 31.8906V9.71355C13.5422 8.51891 14.0171 7.37339 14.8618 6.52865C15.7066 5.68391 16.8521 5.20903 18.0467 5.20834H36.4582V40.2865C36.4575 41.4811 35.9826 42.6266 35.1379 43.4714C34.2931 44.3161 33.1476 44.791 31.953 44.7917H24.203" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <g id="Group">
                                            <path id="Vector_6" d="M20.4556 18.5183L24.5571 14.4219C24.6767 14.3026 24.8387 14.2357 25.0076 14.2357C25.1766 14.2357 25.3386 14.3026 25.4582 14.4219L29.5441 18.5183" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path id="Vector_7" d="M25 28.211V14.7553" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </g>
                                </svg>
                                <h3>Ricarica online</h3>
                                <h5>Lorem ipsum Se sei un cliente privato, gestisci il credito residuo ricaricando ovunque ti trovi o imposta modalità automatiche di ricarica.</h5>
                            </div>
                        </div>

                    </div> */}

                    <Slider >
                        < div className="box">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="apps">
                                    <g id="Group">
                                        <path id="Vector" d="M21.6429 37.1171H13.445C13.1877 37.1281 12.932 37.0714 12.7035 36.9525C12.475 36.8337 12.2817 36.657 12.1429 36.4401C12.0175 36.2163 11.9543 35.9631 11.9598 35.7066C11.9653 35.4502 12.0393 35.1999 12.1741 34.9817L16.2783 27.2135C16.388 26.976 16.5634 26.775 16.7838 26.634C17.0041 26.4931 17.2602 26.4182 17.5218 26.4182C17.7834 26.4182 18.0395 26.4931 18.2598 26.634C18.4802 26.775 18.6556 26.976 18.7653 27.2135L22.932 34.9791C23.0676 35.197 23.1421 35.4474 23.1476 35.704C23.1531 35.9606 23.0894 36.2139 22.9632 36.4375C22.8236 36.658 22.6276 36.8373 22.3955 36.9568C22.1634 37.0763 21.9035 37.1317 21.6429 37.1171V37.1171Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <path id="Vector_2" d="M17.5439 22.2031C16.6218 22.2037 15.7202 21.9306 14.9532 21.4186C14.1862 20.9066 13.5884 20.1786 13.2352 19.3267C12.8821 18.4749 12.7895 17.5374 12.9693 16.6329C13.1491 15.7284 13.5931 14.8976 14.2452 14.2455C14.8973 13.5935 15.7281 13.1494 16.6326 12.9697C17.5371 12.7899 18.4745 12.8824 19.3264 13.2356C20.1783 13.5887 20.9063 14.1866 21.4183 14.9535C21.9303 15.7205 22.2033 16.6221 22.2028 17.5443C22.2014 18.7795 21.7101 19.9637 20.8367 20.8371C19.9633 21.7105 18.7791 22.2018 17.5439 22.2031V22.2031Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <path id="Vector_3" d="M32.4554 26.1641L37.932 30.5469L35.7419 37.1172H29.1715L26.9814 30.5469L32.4554 26.1641Z" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector_4" d="M35.0469 12.8828H29.8672C28.7238 12.8828 27.7969 13.8097 27.7969 14.9531V20.1328C27.7969 21.2762 28.7238 22.2031 29.8672 22.2031H35.0469C36.1903 22.2031 37.1172 21.2762 37.1172 20.1328V14.9531C37.1172 13.8097 36.1903 12.8828 35.0469 12.8828Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <path id="Vector_5" d="M11.4582 7.29166H42.7082V38.5417C42.7082 39.6467 42.2692 40.7065 41.4878 41.4879C40.7064 42.2693 39.6466 42.7083 38.5415 42.7083H11.4582C10.3531 42.7083 9.29329 42.2693 8.51189 41.4879C7.73049 40.7065 7.2915 39.6467 7.2915 38.5417V11.4583C7.2915 10.3533 7.73049 9.29345 8.51189 8.51204C9.29329 7.73064 10.3531 7.29166 11.4582 7.29166V7.29166Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" />
                                    </g>
                                </g>
                            </svg>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_TITLE_OFFER') }}></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_DES_OFFER') }} ></h5>
                        </div>
                        <div className="box">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="document">
                                    <g id="Group">
                                        <path id="path 1" d="M32.2528 21.9375H17.6694" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="path 1_2" d="M32.2528 28.1537H17.6694" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <path id="path 1_3" d="M32.2528 34.375H17.6694" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                        <path id="Shape" d="M11.9453 13.7162H19.8568V6.461" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector" d="M21.5703 5.48703C20.5112 5.48705 19.4867 5.86364 18.6797 6.54953L13.0469 11.3594C12.5623 11.7727 12.1731 12.2861 11.9061 12.8642C11.6391 13.4424 11.5005 14.0715 11.5 14.7084V40.5417C11.5 42.823 13.375 44.6875 15.125 44.6875H34.3333C37.1667 44.6875 38.5 42.823 38.5 40.5443V5.48703H21.5703Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                    </g>
                                </g>
                            </svg>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_TITLE_COST') }} ></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_DES_COST') }}></h5>
                        </div>
                        <div className="box">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="top-up">
                                    <path id="Vector" d="M21.7915 40.6901H28.0415" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path id="Vector_2" d="M12.4165 38.6068C17.5942 38.6068 21.7915 37.1494 21.7915 35.3516C21.7915 33.5537 17.5942 32.0963 12.4165 32.0963C7.23883 32.0963 3.0415 33.5537 3.0415 35.3516C3.0415 37.1494 7.23883 38.6068 12.4165 38.6068Z" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" />
                                    <path id="Vector_3" d="M21.7915 35.3516V41.4974C20.5675 42.9323 16.8436 43.6849 12.4165 43.6849C7.98942 43.6849 4.26546 42.6432 3.0415 41.2083V35.2188" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path id="Vector_4" d="M5.125 42.5234V46.6901C6.34896 48.1224 10.0729 49.0338 14.5 49.0338C18.9271 49.0338 22.651 48.3828 23.875 46.9505V41.7422C23.875 41.7422 23.875 40.7005 21.7917 40.7005" stroke="#E60000" strokeWidth="2" strokeLinejoin="round" />
                                    <path id="Vector_5" d="M13.5415 31.8906V9.71355C13.5422 8.51891 14.0171 7.37339 14.8618 6.52865C15.7066 5.68391 16.8521 5.20903 18.0467 5.20834H36.4582V40.2865C36.4575 41.4811 35.9826 42.6266 35.1379 43.4714C34.2931 44.3161 33.1476 44.791 31.953 44.7917H24.203" stroke="#E60000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                                    <g id="Group">
                                        <path id="Vector_6" d="M20.4556 18.5183L24.5571 14.4219C24.6767 14.3026 24.8387 14.2357 25.0076 14.2357C25.1766 14.2357 25.3386 14.3026 25.4582 14.4219L29.5441 18.5183" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector_7" d="M25 28.211V14.7553" stroke="#E60000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </g>
                            </svg>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_TITLE_RECHARGE') }} ></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_REG_BANNER_DES_RECHARGE') }}></h5>
                        </div>
                    </Slider>
                    {/* <ul className='slider'>
                        <li className={sel == 1 ? 'active' : ''} onClick={e => setSel(1)}></li>
                        <li className={sel == 2 ? 'active' : ''} onClick={e => setSel(2)}></li>
                        <li className={sel == 3 ? 'active' : ''} onClick={e => setSel(3)}></li>

                    </ul> */}




                </div>

            </div>
        </div >
    );
}

export default Registrati;