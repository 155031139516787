import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
// import { setSelectedFactorAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { POPUPTYPE, setCurrentPopupAction } from '../state/appdata'
import { GL } from '../services/services'







const mapState = (state: RootState) => ({
    userdata: state.userdata,
})


const mapDispatch = {
    // selectFactor: (factorid: string) => (setSelectedFactorAction(factorid)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup))
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps

const UserInfo = (props: Props) => {

    const navigate = useNavigate()


    return (
        <div className="popup">
            <div className='closePopup' onClick={e => props.setPopup('NONE')}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 18.5L18.5 5.5M18.5 18.5L5.5 5.5" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
            </svg></div>
            <div className='contentPopup'>
                <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_USER_TITLE') }}></h3>
                <p dangerouslySetInnerHTML={{ __html: GL('ED_MFA_USER_TEXT_1') }} ></p>
                <p dangerouslySetInnerHTML={{ __html: GL('ED_MFA_USER_TEXT_2') }} ></p>
            </div>


        </div >
    )
}

export default connect(
    mapState,
    mapDispatch
)(UserInfo)