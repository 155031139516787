import { useState } from "react"
import { MOCK_SCENARIO, setMockScenario } from "../services/services"
import { mock_responses } from "../services/mock"





export const MockBar = () => {

    const [mScenario, setMScenario] = useState(MOCK_SCENARIO)

    const getMockScenarios = () => {
        let Items: any = []
        let i = 0

        Object.keys(mock_responses).forEach(f => {
            // for (let f of mock_responses) {
            Items.push(
                <option key={'' + i++} value={f}  >{f}</option>
            )
        });
        return Items
    }

    return (
        <div className="mockBar">
            <select onChange={e => {
                setMockScenario(e.target.value)
                setMScenario(e.target.value)
            }} value={mScenario}>
                {getMockScenarios()}
            </select>
        </div>
    )
}