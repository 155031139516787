import { store } from "../state/store"; 
import { isMobile } from "./common-ui";
import { logFE } from "./services";

let utag: any = (window as any).utag
logFE('utag found', utag)
let w:any=window

export const getUtag = () => {
    return (window as any).utag
}

const getMaketLower=()=>{
    let market:string=store.getState().userdata.market||'CONSUMER'
    return market.toLowerCase()
}

const getMaketCamel=()=>{
    let market:string=store.getState().userdata.market||'CONSUMER'
    return (market==='CONSUMER')?'Consumer':'Business';
}



const getEnvironment = () => {
    return isMobile() ? "App Business" : "Web";
}

export const tagPage = (utag_data: any) => {
    let utag = getUtag()
    if (!utag) {
        logFE('************************************  utag is empty *******************************')
        return
    }
    try {
        logFE('performing utag view', utag_data)
        utag.view(utag_data);
    } catch (e) {
        logFE('errorr sendong utag', e)
        try {
            utag.DB("ERROR IN SENDING PAGE VIEW: " + e)
        } catch (error) {
            logFE('error in utag DB',error);
        }
        
    }

}


export const tagLink = (utag_data: any, key: string, value: string) => {
    try {
        utag.link({
            ...utag_data,
            key: value,
            "link_name": utag_data.page_name + ":test",
        })
    } catch (e) {
        logFE('error sending utag link data', e)
        try {
            utag.DB('CUSTOM LINK ERROR: ' + e);
        } catch (error) {
            logFE('error in utag DB',error);
        }
        
    }

}

const getLoginPageUtagData = () => {

    w.utag_data = isMobile() ? {
        page_name: isMobile() ? "MyVodafoneBusiness:accedi" : "accedi",
        page_channel: "Business",
        page_section: "Landing",
        page_subsection: "login",
        page_environment: getEnvironment(),
        journey_name: "login",
        journey_type: "journey",
        event_name: "view",
        visitor_login_status: "logged out"
    } : {
        page_name: "VFIT:accedi",
        page_channel: "consumer",
        page_section: "Self Service",
        page_subsection: "login",
        page_environment: "Web",
        journey_name: "login",
        journey_type: "journey",
        event_name: "view",
        visitor_login_status: "logged out"
    }
    return w.utag_data;
}



export const tagLoginPage = () => {
    setTimeout(() => {
        tagPage(getLoginPageUtagData())
    }, 1260);


}





export const tagClick = (utag_data: any, linkName: string) => {
    let utag = getUtag();
    if (!utag) {
        logFE('utag is empty')
        return
    }

    let link_data = {
        ...utag_data,
        event_name: "ui_interaction",
        event_category: "users",
        event_action: "click",
        link_name: utag_data.page_name + linkName
    }
    logFE('sending utag link :' + linkName)

    try {
        utag.link(link_data)
    } catch (e) {
        logFE('error sending utag link data', e)
       
        try {
            utag.DB('CUSTOM LINK ERROR: ' + e);
        } catch (error) {
            logFE('error in utag DB',error);
        }
    }
}



export const tagHoDienticatoCredenziali = () => {

    tagClick(getLoginPageUtagData(), ":recupera credenziali")
}

export const tagLoginAccediLink = () => {
    tagClick(getLoginPageUtagData(), ":accedi")
}


export const tagLoginQualeUserNameLink = () => {
    tagClick(getLoginPageUtagData(), ":info username");
}

export const tagLoginPageError = (errorMessage: string) => {
    let utag_data = getLoginPageUtagData();
    tagErrorGeneric(utag_data, errorMessage, ":Alert:login error");
}

export const tagErrorGeneric = (utag_data: any, errorMessage: string, category: string) => {
   
    try { 
        let utag = getUtag();
        let link_data = {
            ...utag_data,
            event_name: "form_error",
            event_category: "error",
            form_error: errorMessage,
            link_name: utag_data.page_name + category
        };
    
        logFE('tag login error:', link_data)
        utag.link(link_data);
    } catch (e: any) {
        logFE('error tagging login error',e)
    }
   
}

const getregistrationPageData = () => {
    w.utag_data = (isMobile() ?
        {
            page_name: isMobile() ? "MyVodafoneBusiness:registrati" : "registrati",
            page_channel: "Business",
            page_section: "Landing",
            page_subsection: "registrazione",
            page_environment: getEnvironment(),
            journey_name: "registrazione",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:registrati", //<accedi or registrati>
            page_channel: "consumer",
            page_section: "Self Service",
            page_subsection: "registrazione",
            page_environment: "Web",
            journey_name: "registrazione",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagRegistrationPage = () => {
    return tagPage(getregistrationPageData())
}



const getEnrollmentStartData = () => {
    w.utag_data= (
        isMobile() ? ({
            page_name: "MyVodafoneBusiness:accedi:configure 2fa",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }) : ({
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa",
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        })
    )
    return w.utag_data
}


export const tagEnrollmentStartPage = () => {
    tagPage(getEnrollmentStartData())
}

export const toEseguiClick = () => {
    tagClick(getEnrollmentStartData(), ":procedi");
}



const getEnrollSelectNumber = () => {
    w.utag_data= (
        isMobile() ? ({
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:number ",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }) : ({
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:number",// or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"

        })
    )
    return w.utag_data
}


export const tagEnrollSelectNumberPage = () => {
    tagPage(getEnrollSelectNumber())
}

export const tagEnterNumberManuallyClick = () => {
    tagClick(getEnrollSelectNumber(), ":enter manually")
}

export const tagEnrollSelectNumberInviaCodiceClick = () => {
    tagClick(getEnrollSelectNumber(), ":invia codice");
}



const getInserisciNumeroManualeTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:number:enter manually",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:number:enter manually", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagInserisciNumeroManualmentePageTag = () => {
    tagPage(getInserisciNumeroManualeTagData())
}


export const tagInserisceiNumeroManualmenteClick = () => {
    tagClick(getInserisciNumeroManualeTagData(), ":invia codice");
}

const getEnrollmentEndData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:configure 2fa:typ",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:configure 2fa:typ", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "2FA",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }

    )
    return w.utag_data
}


export const tagEnrollEndPage = () => {
    tagPage(getEnrollmentEndData());
}

const getVerificaIdentitaTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:otp",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:otp", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Service",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagVerificaIdentitaPage = () => {
    tagPage(getVerificaIdentitaTagData());
}

export const tagVerificaIdentitaRichiediNuovoCodiceclick = () => {
    tagClick(getVerificaIdentitaTagData(), ":nuovo codice");
}

export const tagVerificaIdentitaNonAccedoClick = () => {
    tagClick(getVerificaIdentitaTagData(), ":cambia numero");
}



export const verificaIdentitaWrongCodeTag = () => {
    tagErrorGeneric(getVerificaIdentitaTagData(), "incorrect code", ":code error");
}


const getSelezionaNuovoNumeroTagData = () => {
    w.utag_data= (
        isMobile() ? {
            page_name: "MyVodafoneBusiness:accedi:otp:new number",
            page_channel: "Business",
            page_section: "Self Care",
            page_subsection: "login",
            page_type: "2FA",
            page_environment: getEnvironment(),
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        } : {
            page_name: "VFIT:"+getMaketLower()+":accedi:otp:new number", // or <"privati">
            page_channel: getMaketCamel(), // or "consumer"
            page_section: "Self Care",
            page_subsection: "Login",
            page_type: "2FA",
            page_environment: "Web",
            journey_name: "OTP",
            journey_type: "journey",
            event_name: "view",
            visitor_login_status: "logged out"
        }
    )
    return w.utag_data
}

export const tagSelezionaNuovoNumeroPage = () => {
    tagPage(getSelezionaNuovoNumeroTagData())
}


export const tagSelezionaNuovoNumeroInviaCodiceTag = () => {
    tagClick(getSelezionaNuovoNumeroTagData(), ":invia codice")

}

