import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setPasswordAction, setUsernameAction } from '../state/userdata'
import { NavLink, useNavigate } from 'react-router-dom'
import { BigInput } from './BigInput'
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata'
import { GL, RP, logFE, offuscate, sendOTP, verifyOTP } from '../services/services'
import { useEffect, useState } from 'react'
import { AuthFactor } from '../services/be_interfaces'
import { setChallengeIdAction } from '../state/serviceData'
import { clearMSISDNSelection, loginEnd, thereIsOnlyOneDXLEnrolled } from '../services/common-ui'
import { ErrorBox } from './ErrorBox'
import { tagVerificaIdentitaNonAccedoClick, tagVerificaIdentitaPage, tagVerificaIdentitaRichiediNuovoCodiceclick, verificaIdentitaWrongCodeTag } from '../services/utag'

let LocalProps = {
    IS_ENROLLMENT: false
}


const mapState = (state: RootState) => ({
    userdata: state.userdata,
    service: state.service,
    appdata: state.appdata,
    vodaprofile: state.vodaprofile
})


const mapDispatch = {
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setChallenge: (challengeId: string) => (setChallengeIdAction(challengeId)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch
type Lprops = typeof LocalProps

type Props = StateProps & DispatchProps & Lprops

const VerificaIdentitaComponent = (props: Props) => {

    let navigate = useNavigate()
    const [warning, setWarning] = useState(false);
    const [error, setError] = useState(false);
    const [code, setCode] = useState('')


    useEffect(
        () => {
            tagVerificaIdentitaPage();
        },
        []
    )


    const sendOTPLocal = (e: any) => {
        props.setWaiting(true)
        tagVerificaIdentitaRichiediNuovoCodiceclick();


        let factor = null
        // get factorId

        for (let fa of (props.userdata.mfaFactors as AuthFactor[])) {

            if (fa.profileSms && (fa.id === props.userdata.selectedFactorId)) {
                factor = fa
            }
        }
        if(props.userdata.pendingFactors){
            for (let fa of (props.userdata.pendingFactors as AuthFactor[])) {

                if (fa.profileSms && (fa.id === props.userdata.selectedFactorId)) {
                    factor = fa
                }
            }
        }
       

        if (factor) {
            sendOTP(props.userdata.username, factor, props.service.authToken).then(s => {
                props.setWaiting(false)
                if (s.id) {
                    props.setChallenge(s.id)
                }

                setError(false)
                setWarning(true)
            }).catch(e => {
                props.setWaiting(false)
            })
        } else {
            logFE('no factors found')
            props.setWaiting(false)
        }



    }


    const verifyCode = (aCode: string): void => {
        props.setWaiting(true)
        logFE('verifing code')
        verifyOTP(props.userdata.username, aCode, props.service.challengeId, props.service.authToken).then(d => {
            if (d.status === 'OK') {
                if (props.IS_ENROLLMENT) {
                    // setError(true)
                    props.setWaiting(false)
                    navigate('/enrollend')
                } else {
                    logFE('login terminated')
                    if (props.appdata.redirectURL) {
                        // redirect to the destination
                        logFE('redirecting to Destination')
                        window.location.href = props.appdata.redirectURL
                    }

                    props.setPopup('NONE')
                    loginEnd()
                    props.setWaiting(false)

                }
            } else {
                props.setWaiting(false)
                verificaIdentitaWrongCodeTag();
                setError(true)
                setWarning(false)
                setCode('')
            }

        }).catch(err => {
            props.setWaiting(false)
            setError(true)
            setWarning(false)
            setCode('')
        })



    }
    let isEnroll = (props.appdata.appPath === 'MFA_ENROLL')

    return (
        <div className="popup verificaIdentita">
            <div className='closePopup' onClick={e =>props.setPopup('NONE')}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 18.5L18.5 5.5M18.5 18.5L5.5 5.5" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
            </svg></div>
            <div className='contentPopup'>
                <h3 dangerouslySetInnerHTML={{ __html: GL(isEnroll ? 'ED_MFA_OTP_TITLE' : 'ED_MFA_OTP_TITLE_LOGIN') }}></h3>
                <p dangerouslySetInnerHTML={{ __html: GL(isEnroll ? 'ED_MFA_OTP_DES' : 'ED_MFA_OTP_DES_LOGIN') }}></p>
                <center>
                    <p dangerouslySetInnerHTML={{ __html: RP((GL(isEnroll ? 'ED_MFA_OTP_TEXT' : 'ED_MFA_OTP_TEXT_LOGIN')), (isEnroll ? props.userdata.selectedMSISDN : offuscate(props.userdata.selectedMSISDN, 4))) }}></p>

                    {
                        error ? (

                            <ErrorBox text={GL('ED_MFA_OTP_ERROR')} type='ERROR' />

                        ) : (
                            <div></div>
                        )

                    }
                </center>
                <BigInput onFilled={
                    verifyCode
                } code={code}
                    setCode={setCode}
                />

                <div className='bottomSection'>
                    <p>{GL(isEnroll ? 'ED_MFA_OTP_HELP' : 'ED_MFA_OTP_HELP_LOGIN')}</p>
                    <a onClick={
                        sendOTPLocal
                    }>{GL(isEnroll ? 'ED_MFA_OTP_REQUEST' : 'ED_MFA_OTP_REQUEST_LOGIN')}</a>

                    <a onClick={e => {
                        tagVerificaIdentitaNonAccedoClick();
                        clearMSISDNSelection();
                        if (isEnroll) {
                            logFE('in enroll')
                            let nums=[...props.vodaprofile.bos,...props.vodaprofile.contactNums]
                            if (!nums || nums.length < 1) {
                                logFE('insert manually')
                                props.setPopup('INSERISCI_NUMERO_MANUALMENTE')
                            } else {
                                logFE('popup none')
                                props.setPopup('NONE')
                            }

                        } else {
                            if(thereIsOnlyOneDXLEnrolled()){
                                props.setPopup('TI_AIUTIAMO_NOI')
                            }else{
                                props.setPopup('INSERISCI_NUOVO_NUMERO')
                            }
                            
                        }
                    }}>{GL(isEnroll ? 'ED_MFA_OTP_NEW_NUMBER' : 'ED_MFA_OTP_NEW_NUMBER_LOGIN')}</a>





                </div>
                {
                    warning ? (

                        <ErrorBox text={GL('ED_MFA_OTP_WARNING')} type='MESSAGE' />
                    ) : (
                        <div></div>
                    )

                }
            </div>

        </div >
    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(VerificaIdentitaComponent)