import { stat } from "fs";
import { login } from "../services/services"
import { Action } from "./action"


export type LOGIN_STATUS = 'LOGGED' | 'UNLOGGED' | 'REQUESTING_OTP';

const InitialState = {
    authToken: '',
    challengeId: ''
}





const SET_AUTH_TOKEN_ACTION_TYPE: string = 'SET_AUTH_TOKEN'

const SET_CHALLENGE_ID_ACTION_TYPE: string = 'SET_CHALLENGE_ID'






export const setAuthTokenAction = (token: String): Action => {
    return ({
        type: SET_AUTH_TOKEN_ACTION_TYPE,
        payload: token
    })
}

export const setChallengeIdAction = (challengeId: String): Action => {
    return ({
        type: SET_CHALLENGE_ID_ACTION_TYPE,
        payload: challengeId
    })
}






export function serviceDataReducer(state = InitialState, action: any) {
    switch (action.type) {
        case SET_AUTH_TOKEN_ACTION_TYPE:
            return { ...state, authToken: action.payload }
        case SET_CHALLENGE_ID_ACTION_TYPE:
            return { ...state, challengeId: action.payload }
        default:
            return state
    }
}