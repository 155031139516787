import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { NavLink, useNavigate } from 'react-router-dom'
import { BigInput } from './BigInput'
import { GL } from '../services/services'


type Props = {
    page: 'LOGIN' | 'REGISTER'
}



const LoginRegNavigatorComponent = (props: Props) => {

    let navigate = useNavigate()

    // let middle = <div className='hor-box'>
    //     <div> {GL('ED_MFA_SECTION_ACCESS')}</div>
    //     <div><NavLink to='/register'>{GL('ED_MFA_SECTION_REGISTRATION')}</NavLink> </div>
    // </div >
    // if (props.page === 'REGISTER') {
    //     middle = <div className='hor-box'>

    //         <div><NavLink to='/login'>{GL('ED_MFA_SECTION_ACCESS')}</NavLink> </div>
    //         <div> {GL('ED_MFA_SECTION_REGISTRATION')}</div>
    //     </div >
    // }





    return (
        <div >
            <div className="header">
                <div className="section" >
                    <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_VF_LOGO') }}></div>
                    <h2 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_TITLE') }}></h2>
                </div>
            </div>

            <div className="tabs-ui">
                <div className="link-container">
                    <ul>
                        <li className={props.page === 'REGISTER' ? "" : "ui-state-active"}>
                            <a onClick={e => navigate('/login')}>
                                {GL('ED_MFA_SECTION_ACCESS')}
                            </a>
                            <span className="tabIndicator"></span>
                        </li>
                        <li className={props.page === 'REGISTER' ? "ui-state-active" : ""}>
                            <a onClick={e => navigate('/register')} >
                                {GL('ED_MFA_SECTION_REGISTRATION')}
                            </a>
                            <span className="tabIndicator"></span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* {middle} */}

        </div >
    )
}

// Typical usage: `connect` is called after the component is defined
export default LoginRegNavigatorComponent