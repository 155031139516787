import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import { userdataReducer } from './userdata'
import logger from 'redux-logger'
import { vodaReducer } from './vodaprofile'
import { appdataReducer } from './appdata'
import { serviceDataReducer } from './serviceData'
// ...



let __isdebug:boolean|undefined=undefined
export const isDebug = ()=>{
    if(__isdebug==undefined){
        const searchParams: any = new URLSearchParams(document.location.search)
        let param=searchParams.get('DEBUG')||searchParams.get('debug')||''
        __isdebug=param.toLowerCase()==='true'
    }
    return __isdebug
    
} 


export const store = configureStore({
    reducer: {
        userdata: userdataReducer,
        vodaprofile: vodaReducer,
        appdata: appdataReducer,
        service: serviceDataReducer

    },
    middleware: (getDefaultMiddleware) => isDebug() ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware()
})



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch