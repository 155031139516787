import { AddFactorResponse, ChallengeResponse, EnrollResponse, LoginStatusResponse, PortalLoginResponse, ValidateResponse } from "./be_interfaces"

export interface MockUnit {
    login?: PortalLoginResponse,
    retrieveUser?: any,
    createChallenge?: ChallengeResponse,
    addFactor?:AddFactorResponse,
    verifyOTP?: ValidateResponse,
    loginStatus?: LoginStatusResponse,
    enroll?: EnrollResponse
}


export const mock_responses: { [key: string]: MockUnit } =

{
    'SIMPLE_LOGIN': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            authToken: 'xxxxxxxxx',
            name: 'Rossi',
            surname: 'Mario',
            authFactors: [
                {
                    id: '1',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    verified:true,
                    profileSms: {
                        msisdn: '34567899',
                        language: 'Italian'
                    }

                },
                {
                    id: '2',
                    kind: 'SMS',
                    label: 'DECONDO NUMERO',
                    profileSms: {
                        msisdn: '24783445',
                        language: 'Italian'
                    },

                }, {
                    id: '3',
                    kind: 'SMS',
                    label: 'NUMERO DI RISERVA',
                    profileSms: {
                        msisdn: '355865299',
                        language: 'Italian'
                    },

                }
            ],
            defaultFactorId: '1',
            regNumbers: null

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '76w786eqw7ew7q8678ewq678ewq'
        }
    },
    'SIMPLE_SINGLE_LOGIN': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            authToken: 'xxxxxxxxx',
            name: 'Rossi',
            surname: 'Mario',
            authFactors: [
                {
                    id: '1',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    verified:true,
                    profileSms: {
                        msisdn: '34567899',
                        language: 'Italian'
                    }

                }
            ],
            defaultFactorId: '1',
            regNumbers: null

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '76w786eqw7ew7q8678ewq678ewq'
        }
    },
    'USER_PENDING': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'pending',
            callStatus:'OK',
            authToken: 'xxxxxxxxx',
            name: 'Rossi',
            surname: 'Mario',
            authFactors: [
                {
                    id: '1',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    profileSms: {
                        msisdn: '+393461368705',
                        language: 'Italian'
                    },
                    validated:false

                }
            ],
            defaultFactorId: '1',
            regNumbers: null

        },
        addFactor: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        enroll: {
            factor: {
                id: "0776441c7d54b7072cbed67257136fcd",
                kind: "sms",
                profileSms: {
                    msisdn: "+393331234567",
                    lang: "it-IT"
                },
                label: "Personal Number"
            },
            id: "726656175671671",
            status: "pending"
        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+392361368705",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '76w786eqw7ew7q8678ewq678ewq'
        }
    },
    'USER_PENDING_DOUBLE': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'pending',
            callStatus:'OK',
            authToken: 'xxxxxxxxx',
            name: 'Rossi',
            surname: 'Mario',
            authFactors: [
                {
                    id: '1',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    profileSms: {
                        msisdn: '+393461368705',
                        language: 'Italian'
                    },
                    validated:false

                }, {
                    id: '2',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    profileSms: {
                        msisdn: '+39345667799',
                        language: 'Italian'
                    },
                    validated:false

                }
            ],
            defaultFactorId: '1',
            regNumbers: null

        },
        addFactor: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        enroll: {
            factor: {
                id: "0776441c7d54b7072cbed67257136fcd",
                kind: "sms",
                profileSms: {
                    msisdn: "+393331234567",
                    lang: "it-IT"
                },
                label: "Personal Number"
            },
            id: "726656175671671",
            status: "pending"
        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+392361368705",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '76w786eqw7ew7q8678ewq678ewq'
        }
    },
    'CONSUMER': {
        login: {
            "status": 200
        },
    },
    'WRONG_OTP': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            authToken: 'xxxxxxxxx',
            name: 'Rossi',
            surname: 'Mario',
            authFactors: [
                {
                    id: '1',
                    kind: 'SMS',
                    label: 'PRIMO NUMERO',
                    verified:true,
                    profileSms: {
                        msisdn: '34567899',
                        language: 'Italian'
                    }

                },
                {
                    id: '2',
                    kind: 'SMS',
                    label: 'DECONDO NUMERO',
                    profileSms: {
                        msisdn: '24783445',
                        language: 'Italian'
                    },

                }, {
                    id: '3',
                    kind: 'SMS',
                    label: 'NUMERO DI RISERVA',
                    profileSms: {
                        msisdn: '355865299',
                        language: 'Italian'
                    },

                }
            ],
            defaultFactorId: '1',
            regNumbers: null

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'KO',
            authToken30: '76w786eqw7ew7q8678ewq678ewq'
        }

    },
    'LOGIN_ENROLL': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            'picassoNumsNonDXL': [
                '334762345',
                '396536656'
            ]

        },
        addFactor: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '734hv432g234gjhg43jhg234h4g4g4j43gjh34ghj43h4g4gjhg4jh3g4jg4j3K'
        },
        enroll: {
            factor: {
                id: "0776441c7d54b7072cbed67257136fcd",
                kind: "sms",
                profileSms: {
                    msisdn: "+39334762345",
                    lang: "it-IT"
                },
                label: "Personal Number"
            },
            id: "726656175671671",
            status: "pending"
        },
    },
    'LOGIN_CONTACTNUMS_ENROLL': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            'contactNums': [
                '336663456',
                '396536666'
            ]

        },
        addFactor: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '734hv432g234gjhg43jhg234h4g4g4j43gjh34ghj43h4g4gjhg4jh3g4jg4j3K'
        },
        enroll: {
            factor: {
                id: "0776441c7d54b7072cbed67257136fcd",
                kind: "sms",
                profileSms: {
                    msisdn: "+39334762345",
                    lang: "it-IT"
                },
                label: "Personal Number"
            },
            id: "726656175671671",
            status: "pending"
        },
    },
    'LOGIN_ENROLL_2': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            'picassoNumsNonDXL': [
                '334762345',
                '396536656'
            ]

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '734hv432g234gjhg43jhg234h4g4g4j43gjh34ghj43h4g4gjhg4jh3g4jg4j3K'
        },
        enroll: {
            "id": "7f2f323a4c0949bedcfb44d58554755c",
            "status": "pending",
            "factor": {
                "id": "7cf662069732db2668c22e335bd1056d",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393461368705",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "aws"
            },
            "validateResultSms": {},
            "authorization": {
                "header": "vf-a-customer-identification"
            }

        },
    },
    'LOGIN_ENROLL_NO_NUMBERS': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            'picassoNumsNonDXL': [
            ]

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '734hv432g234gjhg43jhg234h4g4g4j43gjh34ghj43h4g4gjhg4jh3g4jg4j3K'
        },
        enroll: {
            "id": "7f2f323a4c0949bedcfb44d58554755c",
            "status": "pending",
            "factor": {
                "id": "7cf662069732db2668c22e335bd1056d",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393461368705",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "aws"
            },
            "validateResultSms": {},
            "authorization": {
                "header": "vf-a-customer-identification"
            }

        },
    },
    'LOGIN_ENROLL_WRONG_NUMBER': {
        login: {
            "status": 201
        },
        retrieveUser: {
            status: 'OK',
            'picassoNumsNonDXL': [
                '334762345',
                '396536656'
            ]

        },
        createChallenge: {
            "id": "70c071adf868ea64a8ca82fa0e276df6",
            "status": "pending",
            "factor": {
                "id": "006ddad46c2a56943eaa0bce3df3efb4",
                "kind": "sms",
                "profileSms": {
                    "msisdn": "+393331234567",
                    "lang": "it-IT"
                },
                "verified": false,
                "label": "Personal Number"
            },
            "createResultSms": {
                "provider": "otpchallenge"
            },
            "validateResultSms": {}
        },
        verifyOTP: {
            status: 'OK',
            authToken30: '734hv432g234gjhg43jhg234h4g4g4j43gjh34ghj43h4g4gjhg4jh3g4jg4j3K'
        },
        enroll: {
            status:'KO',
            "code" : "authenticator:log",
            "reason" : "Validation failed",
            "details" : [ {
              "target" : "factor.profileSms.msisdn",
              "message" : "Must respect [E164] format."
            } ]
          },
    },
    'TEST_LOGIN_1': {
        login: {
            "status": 201
        },
        retrieveUser: {
            "status": 'OK',
            "authToken": "hdjhjhadhdkjh",
            "name": "ProvaNome",
            "surname": "ProvaCognome",
            "authFactors": [{
                "id": "1",
                "kind": "sms",
                "label": "Prova",
                "profileSMS": {
                    "msisdn": "3400000000",
                    "language": "ITA"
                },
                "profileEmail": null
            }],
            "defaultFactorId": "1"
        },
        enroll: {
            factor: {
                id: "0776441c7d54b7072cbed67257136fcd",
                kind: "sms",
                profileSms: {
                    msisdn: "+393331234567",
                    lang: "it-IT"
                },
                label: "Personal Number"
            },
            id: "726656175671671",
            status: "pending"
        },
        verifyOTP: {
            status: 'OK',
            authToken30: 'JG443J434HJ3JK43GJ4KJ4G4KG3JG4K3JG4KJG4JK4G4JK4GK43M4N3B4NM44V4BBM4CBN34CN44322443243'
        }
    }, 'LOGIN_FAIL': {
        login: {
            "status": 403,
            "errorCode": "BLX_ACCOUNT_PENDING",
            "location":"https://www.google.com"
        },
        retrieveUser: {
            status: 'KO',


        }
    },


    'ALREADY_LOGGED': {
        loginStatus: {
            loginStatus: 'LOGGED'
        }
    },
    'WRONG_PASSWORD': {
        login: {
            status: 401,
            errorCode: 'NBL_PASSWORD_INVALID',
            diagnostic: 'Invalid password'
        }
    },
    'LAST_ATTEMPT': {
        login: {
            status: 401,
            errorCode: 'NBL_PWD_LAST_ATTEMPT_REMAIN',
            diagnostic: 'Invalid password'
        }
    },
    'ACC_PENDING': {
        login: {
            status: 403,
            errorCode: 'OTP_CHANGE/BL_ACCOUNT_PENDING',
            diagnostic: 'per reimpostare pwd',
            location: 'https://www.vodafone.it'
        }
    }

}