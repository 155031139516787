import { GL } from "../services/services"





export const Navbar = () => {


    return (
        <div className="header">
            <nav>
                <ul>
                    <li>
                        <a href={GL('ED_MFA_HD_CONSUMER_LINK')} dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_CONSUMER') }}></a>
                    </li>
                    <li>
                        <a href={GL('ED_MFA_HD_BUSINESS_LINK')} dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_BUSINESS') }}></a>
                    </li>
                    <li>
                        <a href={GL('ED_MFA_HD_BUSINESS_PA_LINK')} dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_BUSINESS_PA') }}></a>
                    </li>
                    <li className="last">
                        <a href={GL('ED_MFA_HD_SHOP_LINK')} dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_SHOP') }} ></a>
                    </li>
                </ul>
            </nav>
        </div >
    )
}