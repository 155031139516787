import { useEffect } from 'react';
import '../base.css';
import { useNavigate } from 'react-router-dom';
import UserPassword from '../components/UserPassword';
import { connect } from 'react-redux';
import { RootState } from '../state/store';
import VerificaIdentita from '../components/VerificaIdentita';
import { setUsernameAction } from '../state/userdata';
import SelezionaNuovoNumero from '../components/SelezionaNuovoNumero';
import LoginRegNavigatorComponent from '../components/LoginRegNavigator';
import UserInfo from '../components/UserInfo';
import AccessoNonDisponibile from '../components/AccessoNonDisponibile';
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata';
import { getLoginStatus } from '../services/common-ui';
import { tagLoginPage } from '../services/utag';
import TiAiutiamoNoi from '../components/TiAiutiamoNoi';
import NumeroNonValido from '../components/NumeroNonValido';
import ErrorInEnrollment from '../components/ErrorInEnrollment';



const mapState = (state: RootState) => ({
    appdata: state.appdata,
    userdata: state.userdata

})


const mapDispatch = {
    setUsername: (userid: string) => (setUsernameAction(userid)),
    setPopup: (popupType: POPUPTYPE) => (setCurrentPopupAction(popupType)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps


const LoginPage = (props: Props) => {
    const navigate = useNavigate()



    useEffect(() => {



        getLoginStatus(navigate)

        tagLoginPage()



    },[])


    let popup = <div></div>
    switch (props.appdata.currentPopup) {
        case 'NONE':
            break;
        case 'VERIFICA_IDENTITA':
            //show verifica Identita popup
            popup = <div className="background">
                <VerificaIdentita IS_ENROLLMENT={false} />
            </div>
            break;
        case 'INSERISCI_NUOVO_NUMERO':
            // show select new number popup
            popup = <div className="background">
                <SelezionaNuovoNumero />
            </div>

            break;
        case 'USERINFO':
            // show select new number popup
            popup = <div className="background">
                <UserInfo />
            </div>

            break;
        case 'ACCESSO_NON_DISPONIBILE':
            popup = <div className="background">
                <AccessoNonDisponibile />
            </div>

            break;
        case 'NUMERO_NON_VALIDO':
        popup = <div className="background">
              <NumeroNonValido/>
            </div>
        break;
        case 'ENROLLMENT_ERROR':
            popup = <div className="background">
                <ErrorInEnrollment />
            </div>
            break;
            case 'TI_AIUTIAMO_NOI':
                popup = <div className="background">
                    <TiAiutiamoNoi />
                </div>
    
                break;
    

        default:
            break;

    }


    {
        return (
            <div className="Page LoginPage">
                <LoginRegNavigatorComponent page={'LOGIN'} />

                <UserPassword />

                {popup}
                {/* <Slider>
                    <div>uno</div>
                    <div>due</div>
                    <div>tre</div>
                    <div>quattro</div>
                    <div>cinque</div>
                </Slider> */}
            </div>
        );
    }



}

// export default LoginPage;

export default connect(
    mapState,
    mapDispatch
)(LoginPage)