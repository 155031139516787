import React, { useEffect, useState } from 'react';
import '../base.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { GL } from '../services/services';
import Slider from '../components/Slider';
import { tagEnrollmentStartPage, toEseguiClick } from '../services/utag';

function EnrollStart() {
    const navigate = useNavigate()
    const [sel, setSel] = useState(1)
    const [unmutable,setUnmutable]=useState(null)

    useEffect(() => {
        tagEnrollmentStartPage()
    }, [unmutable])


    return (
        <div className="Page EnrollStart">
            <div className="header">
                <div className="section" >
                    <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_HD_VF_LOGO') }}></div>

                </div>
            </div>
            <div className='mainContent'>
                {/* <img src={GL('ED_MFA_IMG_USER')} alt="" /> */}
                <div className='userlogo' dangerouslySetInnerHTML={{ __html: GL('ED_MFA_IMG_USER') }}></div>
                <h2>{GL('ED_MFA_WELCOME_BACK')}</h2>
                <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_WELCOME_BACK_MSG') }}></h3>
                <div className='actionbutton'>
                    <button onClick={e => {
                        toEseguiClick();
                         navigate('/enrollSetNumber') 
                    }}>{GL('ED_MFA_CONFIGURATION_CONFIRM')}</button>
                </div>
            </div>
            <div className="secondaryContent">
                {/* <div className="boxes">
                    <div className={"sliderBox " + "set" + sel}>

                        <div className='box'>
                            <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_PHONE_BOOK') }}></div>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_PHONE_TITLE') }}></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_PHONE_DES') }}></h5>
                        </div>
                        <div className='box'>
                            <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_OTP') }}></div>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_OTP_TITLE') }}></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_OTP_DES') }}></h5>
                        </div>
                        <div className='box'>
                            <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_CONFIRM') }}></div>
                            <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_CONFIRM_TITLE') }}></h3>
                            <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_CONFIRM_DES') }}></h5>
                        </div>

                    </div>
                </div>
                <ul className='slider'>
                    <li className={sel == 1 ? 'active' : ''} onClick={e => setSel(1)}></li>
                    <li className={sel == 2 ? 'active' : ''} onClick={e => setSel(2)}></li>
                    <li className={sel == 3 ? 'active' : ''} onClick={e => setSel(3)}></li>

                </ul> */}

                <Slider>
                    <div className='box'>
                        <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_PHONE_BOOK') }}></div>
                        <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_PHONE_TITLE') }}></h3>
                        <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_PHONE_DES') }}></h5>
                    </div>
                    <div className='box'>
                        <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_OTP') }}></div>
                        <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_OTP_TITLE') }}></h3>
                        <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_OTP_DES') }}></h5>
                    </div>
                    <div className='box'>
                        <div dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_IMG_CONFIRM') }}></div>
                        <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_CONFIRM_TITLE') }}></h3>
                        <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_BANNER_CONFIRM_DES') }}></h5>
                    </div>
                </Slider>
            </div>

        </div >

    );
}

export default EnrollStart;